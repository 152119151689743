import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"
import logo from "../img/tuedelbeats-logo.png"

const links = [
  {
    title: "Follow me on Spotify",
    link:
      "https://open.spotify.com/artist/0E8b1fcajAOeSDQCfKpGSV?si=iRXRDVQWS2KH-4vES7gh5A",
    highlight: true,
  },
  {
    title: "Espero on Spotify",
    link:
      "https://open.spotify.com/album/0uXGj4PrBCpnFiEOalTUqK?si=P6gquxpuRgW8gpAW1cxYkg",
  },
  {
    title: "Challenge EP on Spotify",
    link:
      "https://open.spotify.com/album/4yr8xkxwfDZwPmjCgf0UhU?si=FiUvmT4rTLGjQ5bpPWx-nA",
  },
  {
    title: "Support me on Bandcamp",
    link: "https://tuedelbeats.bandcamp.com/follow_me",
  },
  {
    title: "Listen on Apple Music",
    link: "https://music.apple.com/us/artist/t%C3%BCdel-beats/1523380627",
  },
  {
    title: "Listen on Deezer",
    link: "https://www.deezer.com/en/artist/100751142",
  },
  {
    title: "Listen on Tidal",
    link: "https://tidal.com/browse/artist/20421974",
  },
]

export default function Social() {
  return (
    <>
      <Helmet>
        <title>Tüdel Beats</title>
      </Helmet>
      <Layout isMinimal>
        <section className="section">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-narrow">
                <Link to="/" title="back to tuedelbeats.de">
                  <img src={logo} width="60" alt="Tüdel Beats Logo" />
                </Link>
              </div>

              <div className="column">
                <h1 className="title">Tüdel Beats</h1>
              </div>
            </div>

            <div className="column">
              {links.map(({ title, link, highlight }) => (
                <a
                  href={link}
                  className={`button is-large is-fullwidth mb-2 is-primary ${
                    !highlight && "is-light"
                  }`}
                  key={link}
                >
                  {title}
                </a>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
